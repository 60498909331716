.list_group_item_style{
    cursor: pointer;
}

.list_group_item_style:hover{
    background-color: #dadada;
    border-color: #dadada;
}

.list_group_img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 30px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    overflow: hidden;
}