#tabsExpenses {
    white-space: nowrap;
    display: block !important;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch
  }
  
  #tabsExpenses button {
    display: inline-block
  }