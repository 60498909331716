.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}

.editor-class {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ccc;
    min-height: 400px;
}

.editor-class-readonly {
    background-color: #E9ECEF;
    padding: 1rem;
    border: 1px solid #ccc;
    min-height: 400px;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.toolbar-class-readonly {
    display: none
}

div.rdw-image-wrapper{
    display: none;
}