.chat_image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 50px;
    min-width: 100px;
    width: 100px;
    height: 100px;
    overflow: hidden;
}