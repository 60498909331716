.icon-qtd-chamados, .img-qtd-chamados {
    vertical-align: middle;
}

.icon-qtd-chamados{
    border-radius: 5px;
    font-size: 18px;
}

.img-qtd-chamados{
    background-color: white;
    border-radius: 5px;
    width: 18px;
    height: 18px;
}