.rdt_TableCell>div{
    text-align: center;
    width: 100%;
}

.rdt_TableCell{
    padding: 0 .1rem !important;
}

.rdt_TableCol{
    padding: 0 .1rem !important;
}

.rdt_TableCol>div, .rdt_TableCol_Sortable>div{
    text-align: center;
    padding: 0;
    width: 100%;
}