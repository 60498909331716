label{
    font-size: small;
}

.danger-color {
    background-color: rgb(255 165 156);
}

.success-color {
    background-color: rgb(203 255 198);
}

.warning-color {
    background-color: rgb(254 255 196);
}

.info-color {
    background-color: rgba(138, 196, 255, 0.36);
}

.custom-style {
    color: 'black'
}

.custom-style:hover{
    cursor: 'pointer';
    opacity: 0.8;
}

